import 'core-js/stable'

import './components/blogScrollableSlideShow'
import './components/blogAside'
import './components/blogAsideEmailShare'
import './components/blogAsideToc'
import './components/blogPost'
import './components/blogPostFooter'
import './components/blogSearch'
import './components/blogTopScrollPanel'
import './components/blogFeaturedPosts'
import './components/blogFeaturedPostsNew'
import './components/blogArticlesHeadersNav'
import './components/cancellation'
import './components/contact'
import './components/cookie'
import './components/collapse'
import './components/documents'
import './components/examplesRoot'
import './components/examplesSearch'
import './components/examplesCardsSlider'
import './components/examplesSeniority'
import './components/footer'
import './components/nav'
import './components/pricing'
import './components/showcase'
import './components/documentExamples'
import './components/resumeTemplates'
import './components/templatesRoot'
import './components/templatesCard'
import './components/trustpilotSlider'
import './components/videoCta'
import './components/partnerBanner'
import './components/builderHero'
import './components/builderBenefits'
import './components/builderResources'
import './components/builderHow'
import './components/homeResumeMaker'
import './components/homeTemplates'
import './components/homeHow'
import './components/homeLetters'
import './components/subscribeNewsletter'
import './components/pageLoader'
import './components/aboutUsHistory'
import './components/aboutUsLeadership'
import './components/aboutUsFeatureGallery'
import './components/aboutUsHero'
import './components/homeBlogSlider'
import './components/homePodcastSlider'
import './components/popup-modal'

import './support/anchor'
import './support/lazyLoader'
import './support/clickTracker'
import './support/setVw'

import './views/affiliates-signup'
import './views/sponsorship'
import './views/billing'
import './views/home'
